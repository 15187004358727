import React from "react";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import EnterPage from './pages/enter';
import HomePage from './pages/home';
import ParallaxPage from './pages/parallaxPage';
import ClassesPage from './pages/classes';



export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<EnterPage />} />
        <Route path="/home" element={<HomePage />}>
          <Route path="about" element={<ParallaxPage />} />
          <Route path="classes" element={<ClassesPage />} />
        </Route>
        <Route path="*" element={<div />} />
      </Routes>
    </BrowserRouter>
  );
}
