import React from 'react';
import { Box} from '@mui/material/';


const Loading = () => {
  return (
    <Box  className="containerEnter">
      <img alt='logo' height={100} className='logoSpinner' src="/images/pussylogo.png"/>
        {/* <div className="lds-ring"><div></div><div></div><div></div><div></div></div> */}
    </Box>
  );
};

export default Loading;