import React from 'react';
import Paper from '@mui/material//Paper';
import Typography from '@mui/material//Typography';

const FictionTitle = () => (
  <Paper variant='outlined' sx={{px:3,py:2}}>
    <Typography variant='h4' align="center" >
      Fiction
    </Typography>
  </Paper>
)

export default FictionTitle;
