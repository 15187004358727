import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

import { styled, useTheme } from "@mui/material/styles";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import AdbIcon from "@mui/icons-material/Adb";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Card from "../NftCard";
import CircleItemSmall from "../ClassItemSmall";
import CircleSpec from "../CircleSpec";
import StatItemBig from "../StatItemBig";

import SwipeableViews from "react-swipeable-views-react-18-fix";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { FaEthereum } from "react-icons/fa";
import { FaBowlRice } from "react-icons/fa6";
import { GiRobotHelmet, GiClassicalKnowledge } from "react-icons/gi";
import { CgCalendarDates } from "react-icons/cg";
import { GoStack } from "react-icons/go";
import { BiBody } from "react-icons/bi";

import collectible1 from "../../images/pussymonkey1.png";
import collectible2 from "../../images/pussymonkey2.png";
import collectible3 from "../../images/pussymonkey3.png";
import { LanguageContext } from "../../contexts/languageContext";

export const cards = [
  {
    key: 34984397,
    content: <Card imagen={collectible1} dense />,
  },
  {
    key: 234923904,
    content: <Card imagen={collectible2} dense />,
  },
  {
    key: 582094,
    content: <Card imagen={collectible3} dense />,
  },
  {
    key: 583450834,
    content: <Card imagen={collectible2} dense />,
  },
  {
    key: 348534908,
    content: <Card imagen={collectible3} dense />,
  },
];

export function Carroussel(props) {
  const table = props.cards.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) };
  });

  const [offsetRadius, /*setOffsetRadius*/] = useState(2);
  const [showArrows, /*setShowArrows*/] = useState(false);
  const [goToSlide, setGoToSlide] = useState(0);
  const [cards] = useState(table);

  // useEffect(() => {
  //   setOffsetRadius(props.offset);
  //   setShowArrows(props.showArrows);

  //   let interval;
  //   let isPaused = false;

  //   const handlePause = () => {
  //     if (!isPaused) {
  //       clearInterval(interval);
  //       isPaused = true;
  //       setTimeout(() => {
  //         interval = setInterval(() => {
  //           setGoToSlide((goToSlide) => goToSlide + 1);
  //         }, 3000);
  //         isPaused = false;
  //       }, 5000); // Adjust the pause duration as needed
  //     }
  //   };

  //   const handleClick = () => {
  //     handlePause();
  //   };

  //   const handleKeyPress = (event) => {
  //     if (event.key === " ") {
  //       handlePause();
  //     }
  //   };

  //   interval = setInterval(() => {
  //     setGoToSlide((goToSlide) => goToSlide + 1);
  //   }, 3000);

  //   window.addEventListener("click", handleClick);
  //   window.addEventListener("keypress", handleKeyPress);

  //   return () => {
  //     clearInterval(interval);
  //     window.removeEventListener("click", handleClick);
  //     window.removeEventListener("keypress", handleKeyPress);
  //   };
  // }, [props.offset, props.showArrows]);

  return (
    <div
      style={{ width: props.width, height: props.height, margin: props.margin }}
    >
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={config.gentle}
      />
    </div>
  );
}

const CustomPaper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
}));

const CustomPaperBlack = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  background: "black",
}));

export default function NftStatics() {
  const { t } = React.useContext(LanguageContext);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const theme = useTheme();
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <>
      <Container maxWidth={"lg"}>
        {matches && (
          <Grid container>
            <Grid item sm={3} p={1}>
              <Specs />
            </Grid>
            <Grid item xs={6} sm={6} container p={1}>
              <Details />
            </Grid>
            <Grid item container sm={3} p={1}>
              <Allocation />
            </Grid>
          </Grid>
        )}

        {!matches && (
          <>
            <Box sx={{ width: "100%" }}>
              <Typography variant="h4" textAlign="center">
              {t("detailesNspecs")}
              </Typography>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                sx={{ marginTop: "25px" }}
              >
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Specs" {...a11yProps(1)} />
                <Tab label="More" {...a11yProps(2)} />
              </Tabs>

              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel p={1} value={value} index={0} dir={theme.direction}>
                  <Details />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <Specs />
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                  <Allocation />
                </TabPanel>
              </SwipeableViews>
            </Box>{" "}
          </>
        )}
      </Container>
    </>
  );
}

const Specs = () => {
  const { t } = React.useContext(LanguageContext);

  // const CircleSpec1 = styled("div")({
  //   boxShadow: `0 2px 3px 1px #FF3131`,
  // });

  // const CircleSpec2 = styled("div")({
  //   boxShadow: `0 2px 3px 1px #FFF01F`,
  // });

  // const CircleSpec3 = styled("div")({
  //   boxShadow: `0 2px 3px 1px #39FF14`,
  // });

  // const CircleSpec4 = styled("div")({
  //   boxShadow: `0 2px 3px 1px #BC13FE`,
  // });

  return (
    <Grid item component={CustomPaper} p={2}>
      <Typography variant="h5" textAlign="center" mb={4}>
        {t("collectionsSpecs")}
      </Typography>
      <StatItemBig
        icon={<GoStack fontSize={"2rem"} />}
        content="11111"
        title={t("totalNfts")}
      />
      <StatItemBig
        icon={<FaEthereum fontSize={"2rem"} />}
        content="TBA"
        title={t("price")}
      />
      <StatItemBig
        icon={<CgCalendarDates fontSize={"2rem"} />}
        content="TBA"
        title={t("date")}
      />
      <Grid container>
        <Grid item justifyContent="center" xs={6}>
          <CircleSpec
            icon={<FaBowlRice />}
            // passedClass={classes.circleSpec1}
            content="300"
            title={t("uniqueTraits")}
          />
        </Grid>
        <Grid item justifyContent="center" xs={6}>
          <CircleSpec
            icon={<GiClassicalKnowledge />}
            // passedClass={classes.circleSpec2}
            content="8"
            title={t("utilityClasses")}
          />
        </Grid>
        <Grid item justifyContent="center" xs={6}>
          <CircleSpec
            icon={<GiRobotHelmet />}
            // passedClass={classes.circleSpec3}
            content="6"
            title={t("indicators")}
          />
        </Grid>
        <Grid item justifyContent="center" xs={6}>
          <CircleSpec
            icon={<BiBody />}
            // passedClass={classes.circleSpec4}
            content="4"
            title={t("bodySizes")}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const Details = () => {
  const { t } = React.useContext(LanguageContext);
  return (
    <Grid item xs container direction="column">
      <Grid
        item
        xs
        container
        alignItems="center"
        component={CustomPaperBlack}
        p={4}
        mb={2}
        sx={{ backgroundColor: "black" }}
      >
        <Carroussel
          cards={cards}
          height={"250px"}
          // height={matches? "auto" : '300px'}
          width="70%"
          margin="0 auto"
          offset={2}
          showArrows={false}
        />
      </Grid>
      <Grid item component={CustomPaper} p={2}>
        <Typography variant="body1">
        {t("detailsContent")}
        </Typography>
      </Grid>
    </Grid>
  );
};

const Allocation = () => {
  const { t } = React.useContext(LanguageContext);
  const navigate = useNavigate();
  return (
    <>
      <Grid item component={CustomPaper} p={2} mb={2} container>
        <Grid container justifyContent={"space-around"} mb={2}>
          <Grid item xs={6}>
            {" "}
            <Typography variant="h6" textAlign="center" color="primary">
            {t("utilityClasses")}
            </Typography>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <Link onClick={() => navigate("/classes")}>
              <Button variant="contained">{t("more")} </Button>
            </Link>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={3}>
            <CircleItemSmall icon={<AdbIcon />} title={t("class1")} />
          </Grid>
          <Grid item xs={3}>
            <CircleItemSmall icon={<AdbIcon />} title={t("class2")} />
          </Grid>
          <Grid item xs={3}>
            <CircleItemSmall icon={<AdbIcon />} title={t("class3")}  />
          </Grid>
          <Grid item xs={3}>
            <CircleItemSmall icon={<AdbIcon />} title={t("class4")}  />
          </Grid>
          <Grid item xs={3}>
            <CircleItemSmall icon={<AdbIcon />} title={t("class5")}  />
          </Grid>
          <Grid item xs={3}>
            <CircleItemSmall icon={<AdbIcon />} title={t("class6")}  />
          </Grid>
          <Grid item xs={3}>
            <CircleItemSmall icon={<AdbIcon />} title={t("class7")}  />
          </Grid>
          <Grid item xs={3}>
            <CircleItemSmall icon={<AdbIcon />} title={t("class8")}  />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} component={CustomPaper} p={2} container>
        <Typography variant="h6" gutterBottom m={"auto"}>
        {t("collectionAllocation")}
        </Typography>
        <Pie width={200} data={data} />
      </Grid>
    </>
  );
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const data = {
  labels: ["Team", "Whitelist", "Free mint", "Promotion", "Public Sale"],
  datasets: [
    {
      label: "# of NFTs",
      data: [1200, 1900, 3000, 500, 200, 300],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

ChartJS.register(ArcElement, Tooltip, Legend);
