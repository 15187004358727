import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

export default function CircleItemSmall(props) {
  const StatItemBg = styled(Grid)(({ theme }) => ({
    borderRadius:12,
    background: theme.palette.background.paper,
    border:`1px solid ${theme.palette.divider}`
  }));
  return (
  <StatItemBg  item container py={2} mb={2}>
  <Grid item xs={4} container alignItems="center" justifyContent="center" textAlign="center">{props.icon}</Grid>
  <Grid item xs={8} container justifyContent="center" alignItems="center">
      <Grid item xs={12}>
          <Typography variant='body2' color="textSecondary" align="center">{props.title}</Typography>
      </Grid>
      <Grid item xs={12}>
          <Typography align="center" variant='h6'>{props.content}</Typography>
      </Grid>

  </Grid>
</StatItemBg>
  )
}