import React from 'react';
import { Box, Typography, Link, Grid, Button, } from '@mui/material';
import styled from '@emotion/styled';
import { LanguageContext } from "../contexts/languageContext";

import { RiTwitterXFill} from 'react-icons/ri';
import {FaDiscord } from 'react-icons/fa';

export function Copyright() {
  const { t } = React.useContext(LanguageContext);
  return (
    <div variant="body2" color="textSecondary">
      {'Copyright © '}
      <Link color="inherit" href="https://Terkemz.xyz/">
      {t("logo")}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </div>
  );
}
const Footer = () => {
  const { t } = React.useContext(LanguageContext);
  // const date = new Date().getFullYear();

  const FooterContainer = styled(Box)({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    miHeight: '10vh',
    padding: '20px',
    justifyContent: 'center',
    flexDirection: 'column',
  });

  const FooterDate = styled(Box)({
    opacity: '0.4',
  });

  return (
    <FooterContainer sx={{ flexGrow: 1 }}>
            <Typography textAlign="center" variant='h3' gutterBottom fontWeight="bold"> {t("footerTitle")}</Typography>
            <Typography textAlign="center" variant='h5' color="text.secondary" gutterBottom sx={{mb:5}}> {t("footerSubtitle")}</Typography>
            <Grid  container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
            <Grid item container  justifyContent="center" xs={12} md={4} sx={{mb:10}}>
              <Grid href='http://twitter.com/0xsolero' component={Button} item sx={{m:2,height:70,width:70,borderRadius:2 ,border:'1px solid #1AFFAE'}}>
                {/* <img src={TwitterIcon} height={25}/> */}
                <RiTwitterXFill fontSize={'2rem'} />
              </Grid>
              <Grid href='http://twitter.com/0xsolero' component={Button} item sx={{m:2,height:70,width:70,borderRadius:2 ,border:'1px solid #1AFFAE'}}>
              {/* <img src={DiscordIcon}/> */}
              <FaDiscord fontSize={'2rem'}  />
              </Grid>
                
            </Grid>
              </Grid>
              <FooterDate sx={{textAlign:"center",marginBottom:10}} > {t("verifiedPolicy")}</FooterDate>
      <Box style={{paddingBottom:'10px'}}>
      {t("buildBy")}
        <Link href="#" target="_blank" underline="none">
        {t("mainBrandLogo")}
        </Link>
      </Box>

      <FooterDate sx={{textAlign:"center"}} >{t("punchLine")} {Copyright()} </FooterDate>
    </FooterContainer>
  );
};

export default Footer;