import React, { useRef } from "react";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Header from "../components/Header";
import useMediaQuery from "@mui/material//useMediaQuery";
// import { useGlitch } from "react-powerglitch";
import { LanguageContext } from "../contexts/languageContext";

const CustomButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "40px",
    padding: "5px 10px",
    fontSize: "1.2rem",
  },
  width: "240px",
  padding: "10px 20px",
  fontSize: "1.5rem",
  borderRadius: 3,
}));

const Page = ({ offset, gradient, onClick, content }) => {
  const { t } = React.useContext(LanguageContext);

  return (
    <>
      <ParallaxLayer
        offset={offset}
        speed={0}
        onClick={onClick}
        id={content.id}
      >
        <div
          className={"flex__container flex--" + content.class + " flex--active"}
          data-slide="1"
        >
          <div className="flex__item flex__item--left">
            <div className="flex__content">
              <p className="text--sub"> {t("classesTitle")}</p>
              <h1 className="text--big">{content.title} </h1>
              <p className="text--normal">{content.description}</p>
            </div>
            <p className="text__background">{content.title}</p>
          </div>
          <div className="flex__item flex__item--right"></div>
        </div>
      </ParallaxLayer>

      <ParallaxLayer
        offset={offset}
        speed={-0.2}
        // onClick={onClick}
      >
        {/* <Box p={2} className={`slopeEnd ${gradient}`}> */}
        <Box
          className={`slopeEnd`}
          // href={`#${content.id}`}
          // component={Button}
        >
          <img className="class__img" src={content.img} alt="class" />
        </Box>
      </ParallaxLayer>
    </>
  );
};

export default function Classes() {
  const { t } = React.useContext(LanguageContext);
  const [offset, setOffset] = React.useState(0);
  // const parallax = useRef()
  const parallax = useRef(null);
  const bigDevice = useMediaQuery("(min-width:600px)");

  const scroll = (to) => {
    // if (parallax.current) {
    // parallax.current.scrollTo(to)
    setOffset(to);
    // }
  };
  // const glitch = useGlitch({
  //   "playMode": "click",
  //   "createContainers": true,
  //   "hideOverflow": false,
  //   "timing": {
  //     "duration": 250,
  //     "iterations": 1
  //   },
  //   "glitchTimeSpan": {
  //     "start": 0,
  //     "end": 1
  //   },
  //   "shake": {
  //     "velocity": 15,
  //     "amplitudeX": 0.2,
  //     "amplitudeY": 0.2
  //   },
  //   "slice": {
  //     "count": 15,
  //     "velocity": 20,
  //     "minHeight": 0.02,
  //     "maxHeight": 0.15,
  //     "hueRotate": true
  //   },
  //   "pulse": true
  // });

  const classesContent = [
    {
      id: "class0",
      class: "class0",
      img: "/images/pussymonkey1.png",
      title: t("class1Title"),
      description:
      t("class1Description"),
      badge: {
        name: "Delta",
        symbol: "Δ",
      },
      rarityScore: 10,
    },
    {
      id: "class1",
      class: "class1",
      img: "/images/pussymonkey1.png",
      title: t("class2Title"),
      description:
      t("class2Description"),
       badge: {
        name: "Gamma",
        symbol: "Γ",
      },
      rarityScore: 8,
    },
    {
      id: "class2",
      class: "class2",
      img: "/images/pussymonkey1.png",
      title: t("class3Title"),
      description:
      t("class3Description"),
       badge: {
        name: "Beta",
        symbol: "Β",
      },
      rarityScore: 5,
    },
    {
      id: "class3",
      class: "class3",
      img: "/images/pussymonkey1.png",
      title: t("class4Title"),
      description:
      t("class4Description"),
       badge: {
        name: "Alpha",
        symbol: "Α",
      },
      rarityScore: 3,
    },
    {
      id: "class4",
      class: "class4",
      img: "/images/pussymonkey1.png",
      title: t("class5Title"),
      description:
      t("class5Description"),
       badge: {
        name: "Sigma",
        symbol: "Σ",
      },
      rarityScore: 2,
    },
    {
      id: "class5",
      class: "class5",
      img: "/images/pussymonkey1.png",
      title: t("class6Title"),
      description:
      t("class6Description"),
      badge: {
        name: "Omega",
        symbol: "Ω",
      },
      rarityScore: 1,
    },
    {
      id: "class6",
      class: "class6",
      img: "/images/pussymonkey1.png",
      title: t("class7Title"),
      description:
      t("class7Description"),
       badge: {
        name: "Devine",
        symbol: "G",
      },
      rarityScore: 1,
    },
    {
      id: "class7",
      class: "class7",
      img: "/images/pussymonkey1.png",
      title: t("class8Title"),
      description:
      t("class8Description"),
      badge: {
        name: "Devine",
        symbol: "G",
      },
      rarityScore: 1,
    },
  ];
  return (
    <div style={{ background: "black" }}>
      <Header />
      <div class="slider__navi">
        {/* <Grid container alignItems="center" spacing={1} sx={{px:{xs:0,md:1},backgroundColor:'black',borderRadius:2, width:'100%'}}  > */}
        <Box sx={{ backgroundColor: "black", padding: 1, borderRadius: 2 }}>
          {classesContent.map((page, index) => (
            <Grid
              container
              justifyContent="center"
              key={index}
              sx={{ width: "100%", my: 1 }}
            >
              <CustomButton
                fullWidth
                // ref={glitch.ref}
                href={`#${page.id}`}
                // color={offset === index ? '#000000' : page.color}
                onClick={() => scroll(index)}
                variant={offset === index ? "contained" : "outlined"}
              >
                {page.badge.symbol} {bigDevice ? page.title : ""}
              </CustomButton>
            </Grid>
          ))}
          {/* {classesContent.map((page,index) => (
                 <Link  ref={glitch.ref} href={`#${page.id}`}  style={{width:'100%'}}
                  color={offset === index ? '#000000' : page.color}
                  onClick={() => scroll(index)}>
                    <Grid   sx={{width:'100%'}} component={Button} fullWidth variant={offset === index ? 'contained' : 'outlined'} item container justifyContent="center"  key={index} my={1}>
                     
                      <Typography variant='h4' mr={bigDevice? 2 : 0}>{page.badge.symbol} </Typography>
                      <Typography variant='h5' align="center" sx={{ display: { xs: 'none', md: 'flex' }}}> {page.title}</Typography>
                     
                    </Grid>
                    </Link>
                ))} */}
        </Box>
        {/* </Grid> */}
      </div>
      <Parallax
        className="container"
        ref={parallax}
        pages={classesContent.length}
        horizontal
      >
        {/* <Page offset={0} gradient="pink" onClick={() => scroll(1)} />
        <Page offset={1} gradient="teal" onClick={() => scroll(2)} />
        <Page offset={2} gradient="tomato" onClick={() => scroll(0)} /> */}

        {classesContent.map((page, index) => (
          <Page
            pageid={page.id}
            offset={index}
            gradient={page.color}
            content={page}
            onClick={() =>
              index === classesContent.length - 1
                ? scroll(0)
                : scroll(index + 1)
            }
            title={page.title}
            key={`pp_${index}`}
          />
        ))}
      </Parallax>
    </div>
  );
}


