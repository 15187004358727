import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';

export default function CircleIcon(props) {
  const CircleSpec = styled(Grid)({
    width:80,
    height:80,
    borderRadius:100,
  });
  return (
      <Grid container justifyContent="center" alignItems="center" sx={{height:'100%'}} mb={2} pt={1} style={{borderRadius:5}}>
   
         <CircleSpec item direction="column"  container justifyContent="center" alignItems="center" className={`${props.passedClass}`}>
        <Grid item xs={1}></Grid>
        <Grid item xs={3} container alignItems="center" justifyContent="center">{props.icon}</Grid>
        <Grid item xs={6} container justifyContent="center" alignItems="center">
            <Typography variant='h5'  align="center">{props.content}</Typography>
        </Grid>
        <Grid item xs={2}></Grid>
    </CircleSpec>
    <Grid item> <Typography textAlign="center"  variant='body2' >{props.title}</Typography></Grid>
      </Grid>


  )
}
