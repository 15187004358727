import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      //ENTER PAGE
      translations: {
        logo: "pipakia",
        enter: "Enter",
        enterSubtitle:
          "asd sad sad sad s a",
        wear: "Wear",
        bestExpirience: "for the best experience",
        //APP HEADER
        joinUs: "Join us",
        //HERO
        de: "de",
        broke: "pussy",
        free: "free",
        heroSubtitle:
          " Enter the world of pipakia, asd sad sad sads",
        scroll: "scroll",
        down: "down",
        getWhitelisted: "Get pussy",
        //VISION
        vision: "vision",
        visionContent: `asd sa sad sad dsa sad sad 
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd`,
        //FICTION A
        ficitonAtitle: "FIction A title",
        ficitonASubtitle: "FIction A subtitle",
        ficitonAContent: `asd sa sad sad dsa sad sad 
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd`,
        //FICTION B
        ficitonBtitle: "FIction A title",
        ficitonBSubtitle: "FIction A subtitle",
        ficitonBContent:`asd sa sad sad dsa sad sad 
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd`,
        //FICTION C
        ficitonCtitle: "FIction A title",
        ficitonCSubtitle: "FIction A subtitle",
        ficitonCContent: `asd sa sad sad dsa sad sad 
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd`,
        //FICTION B
        ficitonDtitle: "FIction A title",
        ficitonDSubtitle: "FIction A subtitle",
        ficitonDContent: `asd sa sad sad dsa sad sad 
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd`,
        //COLLECTIBLES
        collectibles: "collectibles",
        collectiblesContent: "asd sa sad asd dsa sad sad sad ads sad sad sad sad fs ddgf fdsg dfsg sdfg dfs fds fd",
        collectiblesATitle: "das sad sad sad sad asdt",
        collectiblesASubtitleA: "das sad sad as ",
        collectiblesASubtitleB: "And then ...",
        collectiblesATitleB: "Rsad sad sad asd",
        collectiblesASubtitleC: "sad sad sad sad",
        //STATISTICS
        detailesNspecs: "Details & Specs",
        collectionsSpecs: "Collection Specs",
        totalNfts: "Total ",
        price: " Price",
        date: " Date",
        uniqueTraits: "Unique Traits",
        utilityClasses: "Utility",
        indicators: "INdications",
        bodySizes: "Body Sizes",
        detailsContent: `asd sa sad sad dsa sad sad 
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd`,
        class1: "Class 1",
        class2: "Class 2",
        class3: "Class 3",
        class4: "Class 4",
        class5: "Class 5",
        class6: "Class 6",
        class7: "Class 7",
        class8: "Class 8",
        more: "More",
        collectionAllocation: "More",
        //DNA
        dnaMainTitle: " Our DNA",
        dnaTitleA: "Community",
        dnaSubtitleA: " -Super Title 1",
        dnaDContentA: `asd sa sad sad dsa sad sad 
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd`,
        dnaTitleB: "Community",
        dnaSubtitleB: " -Super Title 1",
        dnaDContentB: `asd sa sad sad dsa sad sad 
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd`,
        dnaTitleC: "Community",
        dnaSubtitleC: " -Super Title 1",
        dnaDContentC: `asd sa sad sad dsa sad sad 
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd
        asd asd asd sadsa fdsf sda fsdf fds asdf sadf sadf sdaf af sd`,
        //MINDMAP
        mindmapTitle: "Mindmap",
        //TEAM
        teamTitle: "Team",
        soleroContent:"sad asd asd sa sd as" ,
        dineroContent:"asd asd sad dsa d" ,
        sigukuContent:"sad sad sad sad sad" ,
        //FAQ
        faqTitle: "FAQ",
        faqTitle1: "Why on ",
        faqContent1: "asd sad sad sad sad sad sda ad asd asd asd asdsa dsa sad sadsd as asd",
        faqTitle2: "How Total?",
        faqContent2: "asd sad sad sad sad sad sda ad asd asd asd asdsa dsa sad sadsd as a",
        faqTitle3: "Wen ",
        faqContent3: "asd sad sad sad sad sad sda ad asd asd asd asdsa dsa sad sadsd as a",
        faqTitle4: "Whats the price",
        faqContent4: "asd sad sad sad sad sad sda ad asd asd asd asdsa dsa sad sadsd as a",
        faqTitle5: "What  can i use",
        faqContent5: "asd sad sad sad sad sad sda ad asd asd asd asdsa dsa sad sadsd as a",
        faqTitle6: "Where can i found more information",
        faqContent6: "asd sad sad sad sad sad sda ad asd asd asd asdsa dsa sad sadsd as a",
        //FOOTER
        footerTitle: "Join the revolution",
        footerSubtitle: "...this is just the beginning",
        verifiedPolicy:"VERIFIED POLICY ID: e3ff4ab89245ede611245beab0443dbcc7ea8ca2c017478e4e8990e2",
        buildBy:"Buidl by",
        mainBrandLogo:"assad as",
        punchLine:"asd asd a",
        //CLASSES
        classesTitle:"pipakia Classes",
        class1Title:"Blacksmith",
        class1Description:"asd sad sad sad sad sad sda ad asd asd asd asdsa dsa sad sadsd as a",
        class2Title:"asdsad",
        class2Description:"asd sad sad sad sad sad sda ad asd asd asd asdsa dsa sad sadsd as a",
        class3Title:"asdasda",
        class3Description:"asd sad sad sad sad sad sda ad asd asd asd asdsa dsa sad sadsd as a",
        class4Title:"asdasd",
        class4Description:"asd sad sad sad sad sad sda ad asd asd asd asdsa dsa sad sadsd as a",
        class5Title:"asdasd",
        class5Description:"asd sad sad sad sad sad sda ad asd asd asd asdsa dsa sad sadsd as a",
        class6Title:"asd",
        class6Description:"asd sad sad sad sad sad sda ad asd asd asd asdsa dsa sad sadsd as awith multiple or all utility perks available",
        class7Title:"sasad",
        class7Description:"asd sad sad sad sad sad sda ad asd asd asd asdsa dsa sad sadsd as a",
        class8Title:"asdsa",
        class8Description:"asd sad sad sad sad sad sda ad asd asd asd asdsa dsa sad sadsd as a",
      },
    },
    es: {
      translations: {
        logo: "pipakia",
        enter: "Ingresar",
      },
    },
    gr: {
      translations: {
        logo: "pipakia",
        enter: "Εισέλθετε",
      },
    },
    ch: {
      translations: {
        //HERO PAGE
        logo: "pipakia",
        enter: "进入",
      },
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  ns: ["translations"],
  defaultNS: "translations",
});

export default i18n;
