import React from 'react'

import AppRoutes from './routes'
import { ParallaxProvider } from 'react-scroll-parallax';
import { LanguageProvider } from './contexts/languageContext';
import { createTheme, ThemeProvider, responsiveFontSizes} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Loading from './components/loading';
import logo from "./images/pussylogo.png"
import './App.css'

let theme = createTheme({
  palette: {
    primary: {
      main: '#1AFFAE',
    },
    secondary: {
      main: '#FF1356',
    },
    text:{
      primary:'#FFFF',
      // secondary:'#FA4F3D'
    },
    background:{
      paper: '#000000',
      default:'#000000'
    },
    warning: {
      main: '#FEFE12',
    },
    info: {
      main: '#6912FE',
    },
    mode:'dark',
    divider:'rgba(0,236,198,0.2)'
  },
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            borderRadius: 12
          },
        },
      },
      Paper: {
        boxShadow: '0px 4px 20px 0px rgba(0, 255, 0, 0.5)', // green box shad,
        styleOverrides: {
          root: {
            boxShadow: '0px 4px 20px 0px rgba(0, 255, 0, 0.5)', // green box shad
          },
        },
        defaultProps: {
       
          webkitBackdropFilter: 'blur(6px)',
        },
      },
    },
    typography: {
      button: {
        fontFamily: '"Russo One", sans-serif'
      },
      h1:{
        fontFamily: [
          '"Russo One", sans-serif'
        ]
      },
      h2:{
        fontFamily: [
          '"Russo One", sans-serif'
        ]
      },
      h3:{
        fontFamily: [
          '"Russo One", sans-serif'
        ]
      },
      h4:{
        fontFamily: [
          '"Russo One", sans-serif'
        ]
      },
      h5:{
        fontFamily: [ 
          '"Russo One", sans-serif'
        ]
      },
      h6:{
        fontFamily: [ 
          '"Russo One", sans-serif'
        ]
      },
      body1:{
        fontFamily: [ 
          '"Russo One", sans-serif'
        ]
      },
      body2:{
        fontFamily: [ 
          '"Russo One", sans-serif'
        ]
      },
      fontFamily: [
        // '"Changa One", cursive',
        // '"Bungee", cursive',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
 });
 theme = responsiveFontSizes(theme);

function App() {
  return (
    <LanguageProvider>
      <ThemeProvider theme={theme}>
      <CssBaseline/>
      <ParallaxProvider>
        <React.Suspense fallback={<em><Loading img={logo}/></em>}>
          <AppRoutes/>
        </React.Suspense>
        </ParallaxProvider>
      </ThemeProvider>
      </LanguageProvider>
  )
}

export default App
