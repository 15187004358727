import * as React from "react";
// import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
// import { TypeAnimation } from 'react-type-animation';
import { useSpring, animated } from "react-spring";
import { LanguageContext } from "../../contexts/languageContext";

export default function Fictionlayer(props) {
  const { t } = React.useContext(LanguageContext);
  const modelStyle = useSpring({
    config: { duration: 1000 },
    from: { opacity: 0 },
    to: { opacity: props.transition ? 1 : 0 },
  });

  const HeroBox = styled(Container)(({ theme }) => ({
    zIndex: 30,
    width: "100%",
    display: "flex",
    minHeight: "600px",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "20px",
    },
  }));

  const FictionGrid = styled(Grid)(({ theme }) => ({
    padding: 2,
    [theme.breakpoints.down("md")]: {
      // margin:0,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "5% auto",
    },
  }));

  return (
    <>
      <HeroBox maxWidth="lg" sx={{ mb: 10, mt: 10, height: "100%" }}>
        <animated.div style={modelStyle}>
          <FictionGrid container justifyContent="center" spacing={2}>
            <Grid
              item
              container
              textAlign="center"
              direction="column"
              md={5}
              xs={12}
              className="glassPanel"
            >
              <Paper variant="outlined" sx={{ padding: 4 }}>
                <Typography variant="h5" gutterBottom>
                {t("ficitonAtitle")}
                </Typography>
                <Typography variant="h6" fontStyle="italic" gutterBottom>
                {t("ficitonASubtitle")}
                </Typography>
                <Typography variant="body1" fontStyle="italic" gutterBottom>
                {t("ficitonAContent")}
                </Typography>
              </Paper>
              {/* <LazyLoadComponent>
                     <TypeAnimation
                    speed={80} 
                  sequence={[
                      "In the year 2140, the final day of bitcoin mining had arrived. The world had changed significantly since the creation of the cryptocurrency, with advancements in technology leading to the rise of cyborgs. These cyborgs, known as the Network Protectors, were tasked with safeguarding the decentralized networks that had become integral to daily life. There were six different types of Network Protectors, each with unique abilities and specialties."
                    ]}
                      wrapper="div"
                  cursor={false}
                  style={{ fontSize: '1.3em'}}
                />
                      </LazyLoadComponent>  */}
            </Grid>
          </FictionGrid>
        </animated.div>
      </HeroBox>
    </>
  );
}
