/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
// import { useGlitch } from 'react-powerglitch';
import { useSpring , animated } from "react-spring";
import { LanguageContext } from "../../contexts/languageContext";

export default function CollectibleModel(props) {
  const { t } = React.useContext(LanguageContext);
  const modelStyle = useSpring({
    config: { duration: 1000 },
    from: { opacity: 1},
    to: {  opacity: props.transition ? 0 : 1
    },
  });

  const pfpStyle = useSpring({
    config: { duration: 1000 },
    from: { opacity: 0},
    to: {  opacity: props.transition ? 1 : 0
    },
  });
    // const glitch = useGlitch({
    //   "glitchTimeSpan": false,
    //     "timing": {
    //       "iterations":1,
    //         "duration": 1000,
    //       "easing": "linear"
    //     }
    //   });

      const ModelBox = styled(Container)(({ theme }) => ({
        zIndex:30,
        width: '100%',
        display: 'flex',
        minHeight: '600px',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
          marginTop:'10px',
          paddingTop:200,
        },
        [theme.breakpoints.up('md')]: {
          marginTop:'20px',
          paddingTop:'15%',
        },
      }));

    return (
    
    <ModelBox maxWidth="lg" style={{height:'100%',textAlign:'center' }}>
        
             <Box sx={{position:'absolute',height:200,marginBottom:50,padding:'0 20px'}}>
              <animated.div style={modelStyle}>
                      <Grid item><Typography variant='h3'> {t("collectiblesATitle")}</Typography></Grid>
                      <Grid item><Typography variant='h5'>{t("collectiblesASubtitleA")}</Typography></Grid>
                      <Grid item><Typography variant='h5' color="textSecondary">{t("collectiblesASubtitleB")}</Typography></Grid>
        
              </animated.div>
            </Box>
             <Box sx={{position:'absolute',maxHeight:'400px',textAlign:'center',display:'flex',justifyContent:'center',padding:'0 20px'}}>
                        <animated.div style={pfpStyle}>
                        <Grid item  ><Typography variant='h3'>{t("collectiblesATitleB")}</Typography></Grid>
                          <Grid item><Typography variant='h5'>{t("collectiblesASubtitleC")}</Typography></Grid>
                          </animated.div>

                             <Box sx={{position:'absolute',textAlign:'center'}}>
                             <animated.div style={modelStyle}>
                            <img
                                 style={{margin:'30% auto 0 auto'}}
                            height={400}
                                src="/images/pussymodel.png"
                                alt="3d model to 2d" 
                            />
                            </animated.div>
                            </Box>
                            <Box sx={{position:'absolute',textAlign:'center'}}>
                            <animated.div style={pfpStyle}>
                            <img
                             style={{margin:'30% auto'}}
                              height={400}
                                // ref={props.transition ? glitch.ref : null}
                                src="/images/pussymonkey1.png"
                                alt="3d model to 2d" 
                            />
                            </animated.div>
                            </Box>
        </Box>
    </ModelBox>
)
 }