import * as React from "react";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import ConstructionIcon from "@mui/icons-material/Construction";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/system";
import { LanguageContext } from "../../contexts/languageContext";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CommunityComp = () => {
  const { t } = React.useContext(LanguageContext);
  return (
    <Grid item className="glassPanel">
      <Grid item container>
        <Grid item xs={6} container>
          <Grid item>
            <Typography variant="h5" gutterBottom>
            {t("dnaTitleA")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" fontStyle="italic" gutterBottom>
            {t("dnaSubtitleA")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <Diversity1Icon sx={{ fontSize: "4rem" }} color="primary" />
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom>
      {t("dnaDContentA")}
      </Typography>
    </Grid>
  );
};

const BuildComp = () => {
  const { t } = React.useContext(LanguageContext);
  return (
    <Grid item className="glassPanel">
      <Grid item container>
        <Grid item xs={6} container>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              {" "}
              {t("dnaTitleB")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" fontStyle="italic" gutterBottom>
            {t("dnaSubtitleB")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <ConstructionIcon sx={{ fontSize: "4rem" }} color="warning" />
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom>
      {t("dnaDContentB")}
      </Typography>
    </Grid>
  );
};
const CoolShitComp = () => {
  const { t } = React.useContext(LanguageContext);
  return (
    <Grid item className="glassPanel">
      <Grid item container>
        <Grid item xs={6} container>
          <Grid item>
            <Typography variant="h5" gutterBottom>
            {t("dnaTitleC")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" fontStyle="italic" gutterBottom>
            {t("dnaSubtitleC")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <WhatshotIcon sx={{ fontSize: "4rem" }} color="info" />
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom>
      {t("dnaDContentC")}
      </Typography>
    </Grid>
  );
};
export default function DNAlayer() {
  const { t } = React.useContext(LanguageContext);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const matches = useMediaQuery("(min-width:600px)");
  const theme = useTheme();

  const AltContainer = styled(Container)(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: "600px",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "20px",
    },
  }));
  return (
    <>
      <AltContainer maxWidth="lg">
        <Grid container alignItems="center" justifyContent="center">
          <Paper variant="outlined" sx={{ px: 3, py: 2, mb: 5 }}>
            {" "}
            <Typography id="fiction" textAlign="center" variant="h4">
            {t("dnaMainTitle")}
            </Typography>
          </Paper>{" "}
        </Grid>
        {!matches && (
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Community" {...a11yProps(0)} />
              <Tab label="We Build" {...a11yProps(1)} />
              <Tab label="Cool Sh*t" {...a11yProps(2)} />
            </Tabs>

            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <Grid item container justifyContent="center" xs={12} md={4}>
                  <CommunityComp />
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <Grid item container justifyContent="center" xs={12} md={4}>
                  <BuildComp />
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <Grid item container justifyContent="center" xs={12} md={4}>
                  <CoolShitComp />
                </Grid>
              </TabPanel>
            </SwipeableViews>
          </Box>
        )}
        {matches && (
          <Grid container direction="row" justifyContent="center" spacing={4}>
            <Grid item container justifyContent="center" xs={12} md={4}>
              <CommunityComp />
            </Grid>
            <Grid item container justifyContent="center" xs={12} md={4}>
              <BuildComp />
            </Grid>
            <Grid item container justifyContent="center" xs={12} md={4}>
              <CoolShitComp />
            </Grid>
          </Grid>
        )}
      </AltContainer>
    </>
  );
}
