import React from 'react';
import Container from '@mui/material//Container';
import Typography from '@mui/material//Typography';
import { LanguageContext } from "../../contexts/languageContext";

const CollectiblesA = () => {
  const { t } = React.useContext(LanguageContext);
  <Container maxWidth="lg">
    <Typography variant='h4' align="center" pt={{xs:2,md:10}} px={{xs:5,md:10}}>
    {t("collectiblesContent")}
    </Typography>
  </Container>
}

export default CollectiblesA;