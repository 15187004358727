/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react'
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import { useSpring , animated } from "react-spring";
import { LanguageContext } from "../../contexts/languageContext";

export default function Visionlayer(props) {
  const { t } = React.useContext(LanguageContext);

  const modelStyle = useSpring({
    config: { duration: 1500 },
    from: { opacity: 0},
    to: {  opacity: props.transition ? 1 : 0
    },
  });
  const HeroBox = styled(Container)(({ theme }) => ({
    zIndex:30,
    width: '100%',
    display: 'flex',
    minHeight: '600px',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop:'10px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop:'20px',
    },
  }));
  return (

    <HeroBox maxWidth={false} sx={{display:'flex'}}>
       <animated.div style={modelStyle}>
      <Grid    container alignItems="center" justifyContent="center">
        <Paper variant='outlined' sx={{px:3,py:2}}>  
        <Typography id="fiction"  align="center" variant='h4' > {t("vision")}</Typography></Paper>
      <Grid container justifyContent="center" spacing={2} sx={{margin:'5% auto',p:2}}>
              <Grid item  justifyContent="center" className='glassPanel' sx={{height:300,width:'70%',textAlign:'center'}}  md={8} xs={11}>
                <Typography variant='h4'>
                {t("visionContent")}
                </Typography>
              </Grid>
        </Grid>
  </Grid>   
  </animated.div>
  </HeroBox>
  );
}



