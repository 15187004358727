import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";
import { LanguageContext } from "../../contexts/languageContext";

export default function FAQLayer() {
  const { t } = React.useContext(LanguageContext);
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const AltContainer = styled(Container)(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: "600px",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "20px",
    },
  }));

  const faqs = [
    {
      key: 11111,
      title: t("faqTitle1"),
      content:
      t("faqContent1"),
    },
    {
      key: 22222,
      title: t("faqTitle2"),
      content:
      t("faqContent2"),
    },
    {
      key: 33333,
      title: t("faqTitle3"),
      content: t("faqContent3"),
    },
    {
      key: 44444,
      title: t("faqTitle4"),
      content: t("faqContent4"),
    },
    {
      key: 55555,
      title: t("faqTitle5"),
      content:
      t("faqContent5"),
    },
    {
      key: 66666,
      title: t("faqTitle6"),
      content: t("faqContent6"),
    },
  ];
  
  return (
    <AltContainer maxWidth="xl" sx={{ mb: 10 }}>
      <Box>
        <Grid container alignItems="center" justifyContent="center">
          <Paper variant="outlined" sx={{ px: 3, py: 2, mb: 5 }}>
            {" "}
            <Typography id="fiction" textAlign="center" variant="h4">
              {t("faqTitle")}
            </Typography>
          </Paper>{" "}
        </Grid>
        {faqs.map((faq, index) => (
          <Accordion
            style={{ widows: "100%" }}
            key={faq.key}
            expanded={expanded === faq.key}
            onChange={handleChange(faq.key)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" sx={{ padding: "20px" }}>
                {faq.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="h6" textAlign="center" gutterBottom>
                {faq.content}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </AltContainer>
  );
}

