import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import Paper from '@mui/material//Paper';
import useMediaQuery from '@mui/material//useMediaQuery';

function Card(props) {
  const [show, setShown] = useState(false);
  const matches = useMediaQuery('(min-width:600px)');
  const props3 = useSpring({
    margin: matches ?'15% 0' : 0,
    background: props.imagen,
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)"
  });
  return (
    <animated.div
      className='smallcard'
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    > <Paper variant="outlined" sx={{padding: !props.dense ? 2.5 : 0,borderRadius:5,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
      <img src={props.imagen} alt="" />
      {props.title && <h2>{props.title}</h2>}
      {props.content && <p>
        {props.content}
      </p>}
      {props.children && <div className='btnn'>
        {props.children}
      </div>} 
      </Paper>
    </animated.div>
  );
}

export default Card;
