import React from "react";
import { LanguageContext } from "../contexts/languageContext"
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Typography,
  Link,
  Box,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Drawer,
  Container,
  Button,
  Grid,
  Paper,
} from "@mui/material";
import { RiTwitterXFill } from "react-icons/ri";
import { FaDiscord } from "react-icons/fa";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import PropTypes from "prop-types";
// import MusicPlayer from './MusicPlayer';
import MenuIcon from "@mui/icons-material/Menu";
// import useStyles from '../styles/styles';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { useGlitch } from "react-powerglitch";
import Slide from "@mui/material/Slide";
// import AdbIcon from "@mui/icons-material/Adb";
// import logo from "../../src/images/pussylogo.png";
import { styled } from "@mui/system";

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const Header = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = React.useContext(LanguageContext);
  // const classes = useStyles();
  const links = [
    { id: 1, route: "about", url: "/home/about" },
    // { id: 2, route: 'fiction', url: '#fiction' },
    // { id: 3, route: 'collectibles', url: '#collectibles' },
    { id: 2, route: "classes", url: "/home/classes" },
    { id: 3, route: "mint", url: "/" },
    { id: 4, route: "app", url: "/" }

    // { id: 5, route: 'team', url: '#team' },
    // { id: 6, route: 'faq', url: '#faq' },
  ];

  // const glitch = useGlitch({
  //   playMode: "hover",
  //   createContainers: true,
  //   hideOverflow: false,
  //   timing: {
  //     duration: 250,
  //     iterations: 1,
  //     easing: "ease-in",
  //   },
  //   glitchTimeSpan: {
  //     start: 0,
  //     end: 1,
  //   },
  //   shake: {
  //     velocity: 15,
  //     amplitudeX: 0.2,
  //     amplitudeY: 0.2,
  //   },
  //   slice: {
  //     count: 6,
  //     velocity: 15,
  //     minHeight: 0.02,
  //     maxHeight: 0.15,
  //     hueRotate: true,
  //   },
  //   pulse: false,
  // });
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 210, textAlign: "center" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {links.map((link, i) => (
          <ListItem
            key={i}
            button
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link
              // href={link.id === 3 || link.id === 4 ? "#" : link.url}
              onClick={() => navigate(link.url)}
              key={link.id}
              sx={{ width: "100%" }}
            >
              <Button
                disabled={link.id === 3 || link.id === 4}
                variant={
                  location.pathname === link.url ? "contained" : "outlined"
                }
                fullWidth
              >
                <ListItemText primary={link.route} />
              </Button>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const ToolBarStyled = styled(Toolbar)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      marginTop: "0",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 2.5%",
    },
  }));

  const Logo = styled(Typography)(({ theme }) => ({
    cursor: "pointer",
    marginRight: 50,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  }));

  const ToolWrapper = styled(Box)(({ theme }) => ({
    borderRadius: 5,
    minWidth: 130,
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: 12,
    },
  }));

  return (
    <Box>
      <HideOnScroll {...props}>
        <AppBar
          elevation={0}
          sx={{
            background:
              "radial-gradient(circle, rgba(0,29,18,0.13898970232624297) 31%, rgba(22,0,0,0.1361885818780637) 95%)",
            backdropFilter: " blur(2px)",
          }}
        >
          <Container maxWidth={false} sx={{}}>
            <ToolBarStyled>
              {!matches && (
                <Grid container alignItems="center">
                  <Grid item container alignItems="center" xs={6} md={2}>
                    <Link
                      onClick={() => navigate("/")}
                      underline="none"
                      style={{ display: "flex", alignItems: "center" }}
                      color="text.primary"
                    >
                      <Typography
                        variant="h4"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                        alt="logo"
                          src="/images/pussylogo.png"
                          style={{ maxWidth: 35, padding: "5px 0" }}
                        />
                      </Typography>
                      <Logo variant="h4">{t("logo")}</Logo>
                    </Link>
                  </Grid>
                  <Grid item sx={{ margin: { xs: "auto", md: 0 } }}>
                    <ToolWrapper sx={{ display: "flex", alignItems: "center" }}>
                      <Grid container alignItems="center">
                        <Grid xs={4} item>
                          <IconButton
                            size="small"
                            edge="end"
                            aria-label="menu"
                            style={{ minWidth: 40, height: "100%" }}
                          >
                            <RiTwitterXFill />
                          </IconButton>
                        </Grid>
                        <Grid xs={4} item>
                          <IconButton
                            size="small"
                            edge="end"
                            // color="primary"
                            aria-label="menu"
                            style={{ minWidth: 40 }}
                          >
                            <FaDiscord />
                            {/* <img src={DiscordIcon} width="auto"  height="25px" /> */}
                          </IconButton>
                        </Grid>
                        <Grid xs={4} item>
                          {/* <MusicPlayer/> */}
                        </Grid>
                      </Grid>
                    </ToolWrapper>
                  </Grid>
                </Grid>
              )}
              {matches && (
                <Grid container alignItems="center">
                  <Grid item container alignItems="center" xs={6} md={2}>
                    {/* <MusicPlayer/> */}
                  </Grid>
                  <Grid item>
                    <ToolWrapper sx={{ display: "flex", alignItems: "center" }}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Link
                            onClick={() => navigate("/")}
                            underline="none"
                            style={{ display: "flex", alignItems: "center" }}
                            color="text.primary"
                          >
                            <Typography
                              variant="h4"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                              alt="logo"
                                src="/images/pussylogo.png"
                                style={{ maxWidth: 40, padding: "5px 0" }}
                              />
                            </Typography>
                          </Link>
                        </Grid>
                      </Grid>
                    </ToolWrapper>
                  </Grid>
                </Grid>
              )}
              {!matches && (
                <Paper
                  sx={{ display: "flex", padding: "4px 0", borderRadius: 3 }}
                >
                  <Link
                    mx={1}
                    // href={links[0].url}
                    // ref={glitch.ref}
                    onClick={() => navigate("/home/about")}
                  >
                    <Button
                      variant={
                        location.pathname === "/home/about" ? "outlined" : "text"
                      }
                    >
                      {links[0].route}
                    </Button>
                  </Link>
                  <Link
                    mx={1}
                    // href={links[1].url}
                    // ref={glitch.ref}
                    onClick={() => navigate("/home/classes")}
                  >
                    <Button
                      variant={
                        location.pathname === "/home/classes" ? "outlined" : "text"
                      }
                    >
                      {links[1].route}
                    </Button>
                  </Link>
                  <Link
                    disabled
                    mx={1}
                //  ref={glitch.ref}
                  >
                    <Button disabled>{links[2].route}</Button>
                  </Link>
                  <Link mx={1} 
                  href={links[3].url} 
                  // ref={glitch.ref}
                  >
                    <Button color="secondary">{links[3].route}</Button>
                  </Link>
                </Paper>
              )}

              {matches ? (
                <Box>
                  <IconButton
                    size="large"
                    edge="end"
                    color="secondary"
                    aria-label="menu"
                    onClick={toggleDrawer("right", true)}
                  >
                    <MenuIcon fontSize="1.2rem" />
                  </IconButton>

                  <Drawer
                    anchor="right"
                    open={state["right"]}
                    onClose={toggleDrawer("right", false)}
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      py={1}
                      direction="column"
                    >
                      <Typography
                        variant="h4"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                        alt="logo"
                          src="/images/pussylogo.png"
                          style={{ maxWidth: 30, padding: "5px 0" }}
                        />
                      </Typography>
                      <Typography variant="h4">{t("logo")}</Typography>
                    </Grid>
                    {list("right")}
                    <Typography textAlign="center">{t("joinUs")}</Typography>
                    <Grid item container justifyContent="center" xs={12} md={4}>
                      <Grid
                        href="http://twitter.com/0xsolero"
                        component={Button}
                        item
                        sx={{
                          m: 1,
                          height: 50,
                          width: 50,
                          borderRadius: 2,
                          border: "1px solid #1AFFAE",
                          minWidth: "none",
                        }}
                      >
                        <RiTwitterXFill fontSize={"1.5rem"} />
                      </Grid>
                      <Grid
                        href="http://twitter.com/0xsolero"
                        component={Button}
                        item
                        sx={{
                          m: 1,
                          height: 50,
                          width: 50,
                          borderRadius: 2,
                          border: "1px solid #1AFFAE",
                        }}
                      >
                        <FaDiscord fontSize={"1.7rem"} />
                      </Grid>
                    </Grid>
                  </Drawer>
                </Box>
              ) : (
                <Box
                  ml={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexGrow: "0.1",
                  }}
                >
                </Box>
              )}
            </ToolBarStyled>
          </Container>
        </AppBar>
      </HideOnScroll>
    </Box>
  );
};

export default Header;
