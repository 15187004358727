import React, { createContext, useState, useEffect } from 'react';
import i18n from '../languages/i18n';

export const LanguageContext = createContext();


export const LanguageProvider = ({ children }) => {
    const [locale, setLocale] = useState(localStorage.getItem('locale') || 'en');
  
    useEffect(() => {
      i18n.changeLanguage(locale);
    }, [locale]);
  
    const changeLanguage = (event) => {
        const newLocale = event.target.value;
        localStorage.setItem('locale', newLocale);
        i18n.changeLanguage(newLocale);
        setLocale(newLocale);
      };
    return (
      <LanguageContext.Provider value={{ locale, changeLanguage, t: i18n.t }}>
        {children}
      </LanguageContext.Provider>
    );
  };