import * as React from 'react';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { useTheme } from '@mui/material/styles';
import Card from '../TeamCard';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TwitterIcon from '@mui/icons-material/Twitter';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/system';

import collectible1 from '../../images/pussymonkey1.png';
import collectible2 from '../../images/pussymonkey1.png';
import collectible3 from '../../images/pussymonkey1.png';

import { LanguageContext } from "../../contexts/languageContext";


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function TeamLayer() {
  const { t } = React.useContext(LanguageContext);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const theme = useTheme();
  const matches = useMediaQuery('(min-width:600px)');

  const AltContainer = styled(Container)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection:'column',
    minHeight: '600px',
    alignItems: 'center',
    height:'100%',
    [theme.breakpoints.down('md')]: {
      marginTop:'10px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop:'20px',
    },
  }));
  return (

       <AltContainer  
            maxWidth="xl">
               <Grid    container alignItems="center" justifyContent="center">
                <Paper variant='outlined' sx={{px:3,py:2,mb:5}}>  <Typography id="fiction"  align="center" variant='h4' >Team</Typography></Paper> </Grid>
                   {!matches &&
                      <Box sx={{ width: '100%' }}>
                        <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        <Tab label="@0xSolero" {...a11yProps(0)} />
                        <Tab label="@Ciloyan" {...a11yProps(1)} />
                        <Tab label="@dinosxxx" {...a11yProps(2)} />
                      </Tabs>
                   
                    <SwipeableViews
                      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                      index={value}
                      onChangeIndex={handleChangeIndex}
                    >
                      <TabPanel value={value} index={0} dir={theme.direction}>
                      <Grid item container  justifyContent="center" xs={12} md={4}>
              <Card imagen={collectible3} title="Solero" content={t("soleroContent")}>
                <Button variant='contained' sx={{borderRadius:5}}  href='http://twitter.com/0xsolero'><TwitterIcon/> @0xSolero</Button>
              </Card>
            </Grid>
                      </TabPanel>
                      <TabPanel value={value} index={1} dir={theme.direction}>
                      <Grid item tem container  justifyContent="center"  xs={12} md={4}>
              <Card imagen={collectible2} title="Siguku" content={t("sigukuContent")} >
              <Button variant='contained' sx={{borderRadius:5}} href='http://twitter.com/Ciloyan'><TwitterIcon/> @Ciloyan</Button>
              </Card>
            </Grid>
                      </TabPanel>
                      <TabPanel value={value} index={2} dir={theme.direction}>
                      <Grid item tem container  justifyContent="center"  xs={12} md={4}>
              <Card imagen={collectible1} id="dinero" title="Dinero" content={t("dineroContent")}>
              <Button variant='contained' sx={{borderRadius:5}} href='http://twitter.com/dinosxxx'><TwitterIcon/> @dinosxxx</Button>
              </Card>
            </Grid>
                      </TabPanel>
                    </SwipeableViews>
                    </Box>

                  }
                  {matches &&

            <Grid  container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
            <Grid item container  justifyContent="center" xs={12} md={4}>
              <Card imagen={collectible3} title="Solero" content={t("soleroContent")}>
                <Button variant='contained' sx={{borderRadius:5}}  href='http://twitter.com/0xsolero'><TwitterIcon/> @0xSolero</Button>
              </Card>
            </Grid>
            <Grid item tem container  justifyContent="center"  xs={12} md={4}>
              <Card imagen={collectible2} title="Siguku" content={t("sigukuContent")}  >
              <Button variant='contained' sx={{borderRadius:5}} href='http://twitter.com/Ciloyan'><TwitterIcon/> @Ciloyan</Button>
              </Card>
            </Grid>
            <Grid item tem container  justifyContent="center"  xs={12} md={4}>
              <Card imagen={collectible1} id="dinero" title="Dinero" content={t("dineroContent")}>
              <Button variant='contained' sx={{borderRadius:5}} href='http://twitter.com/dinosxxx'><TwitterIcon/> @dinosxxx</Button>
              </Card>
            </Grid>
                </Grid>}
      </AltContainer>
  );
}



