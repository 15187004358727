import * as React from 'react';
// import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import { useSpring , animated } from "react-spring";
import { LanguageContext } from "../../contexts/languageContext";

export default function Fictionlayer(props) {
  const { t } = React.useContext(LanguageContext);
  const modelStyle = useSpring({
    config: { duration: 1000 },
    from: { opacity: 0},
    to: {  opacity: props.transition ? 1 : 0
    },
  });
  const HeroBox = styled(Container)(({ theme }) => ({
    zIndex: 30,
    width: "100%",
    display: "flex",
    minHeight: "600px",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "20px",
    },
  }));

  const FictionGrid = styled(Grid)(({ theme }) => ({
    padding: 2,
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.up("md")]: {
      marginTop: "5% auto",
    },
  }));


  return (
    <>
            <HeroBox  
            maxWidth="lg" sx={{mb:10,mt:10,height:'100%'}}>
                    <animated.div style={modelStyle}>
            <FictionGrid container justifyContent="center" spacing={2} >
                    <Grid item container direction="column" textAlign="center" alignContent="center"  md={6} xs={12}  className="glassPanel">
                    <Paper variant="outlined" sx={{ padding: 4 }}>
                <Typography variant="h5" gutterBottom>
                {t("ficitonDtitle")}
                </Typography>
                <Typography variant="h6" fontStyle="italic" gutterBottom>
                {t("ficitonDSubtitle")}
                </Typography>
                <Typography variant="body1" fontStyle="italic" gutterBottom>
                {t("ficitonDContent")}
                </Typography>
              </Paper>
                    </Grid>
              </FictionGrid>
              </animated.div>
        </HeroBox>  
    </>
  );
}



