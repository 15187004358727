import React, { useContext } from "react";
// import { useGlitch } from "react-powerglitch";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Button, Box, Link } from "@mui/material";
import HeadphonesIcon from "@mui/icons-material/Headphones";
// import LanguageSelector from '../components/LanguageSelector';
import { LanguageContext } from "../contexts/languageContext";
import { RiTwitterXFill } from "react-icons/ri";
import { FaDiscord } from "react-icons/fa";
import EnterVideo from "../components/enterVideo";

export function Copyright() {
  const { t } = useContext(LanguageContext);
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ position: "fixed", bottom: 10 }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        xs={12}
        md={4}
        mb={1}
      >
        <Grid
          component={Link}
          color="text.secondary"
          href="http://twitter.com/0xsolero"
          item
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            m: 1,
            height: 40,
            width: 40,
            borderRadius: 2,
            border: "1px solid ",
          }}
        >
          <RiTwitterXFill width={20} height={20} />
        </Grid>
        <Grid
          component={Link}
          color="text.secondary"
          href="http://twitter.com/0xsolero"
          item
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            m: 1,
            height: 40,
            width: 40,
            borderRadius: 2,
            border: "1px solid ",
          }}
        >
          <FaDiscord width={25} height={25} />
        </Grid>
        <Grid item>{/* <LanguageSelector/> */}</Grid>
      </Grid>

      <Typography variant="body2" color="text.secondary">
        {"Copyright © "}
        {t("enter")}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Grid>
  );
}

export default function Enter() {
  const navigate = useNavigate();
  const { t } = useContext(LanguageContext);
  // const glitch = useGlitch({
  //   glitchTimeSpan: false,
  //   timing: {
  //     iterations: 1,
  //     duration: 1000,
  //     easing: "linear",
  //   },
  // });
  return (
    <div>
      <Grid
        className="containerEnter"
        direction="column"
        container
        alignItems="center"
        justifyContent="center"
      >
        <Box>
          <img src="/images/pussylogo.png" height={200} alt="logo"></img>
        </Box>
        <Typography mb={5} variant="h2">
          {t("logo")}
        </Typography>
        <Typography variant="h5" mb={5} textAlign="center">
          {t("enterSubtitle")}
        </Typography>
        <Button
          className="pressStart"
          onClick={() => navigate("/home/about")}
          variant="outlined"
          sx={{
            px: 5,
            backgroundColor: "black",
            borderRadius: 2,
            boxShadow: " 0 7px 20px #00ffd5",
            fontFamily: '"Kanit", sans-serif',
            fontStyle: "italic",
            textTransform: "uppercase",
            fontSize: "2.7rem",
          }}
        >
          {t("enter")}
        </Button>
        <Box sx={{ mt: 5 }} color="text.secondary">
        {t("wear")} <HeadphonesIcon /> {t("bestExpirience")}
        </Box>
        <Box sx={{ mt: 5 }}></Box>
        <Copyright />
      </Grid>
      <EnterVideo />
    </div>
  );
}
