/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import { Waypoint } from 'react-waypoint';

import Hero from '../components/Hero';
import Vision from '../components/parallaxComponents/Vision';
import FictionTitle from '../components/parallaxComponents/fictionTitle';
import FictionA from '../components/parallaxComponents/FictionA';
import FictionB from '../components/parallaxComponents/FictionB';
import FictionC from '../components/parallaxComponents/FictionC';
import FictionD from '../components/parallaxComponents/FictionD';
import CollectiblesTitle from '../components/parallaxComponents/collectiblesTitle';
import CollectiblesA from '../components/parallaxComponents/CollectiblesA';
import CollectiblesB from '../components/parallaxComponents/CollectiblesB';
import NftStatistics from '../components/parallaxComponents/nftStatistics';
import DNA from '../components/parallaxComponents/DNA';
import MindMap from '../components/parallaxComponents/MindMap';
import Team from '../components/parallaxComponents/Team';
import FAQ from '../components/parallaxComponents/FAQ';
import Footer from '../components/Footer';
import NftCard from '../components/NftSmallCard';

import useMediaQuery from '@mui/material//useMediaQuery';

const url = (name, wrap = false) =>
  `${wrap ? 'url(' : ''}https://awv3node-homepage.surge.sh/build/assets/${name}.svg${wrap ? ')' : ''}`

function ParallaxPage() {

  const [showVision, setVision] = useState(false);
  const [showFictionA, setFictionA] = useState(false);
  const [showFictionB, setFictionB] = useState(false);
  const [showFictionC, setFictionC] = useState(false);
  const [showFictionD, setFictionD] = useState(false);
  // const [showModel, setModel] = useState(false);
  const [showPFP, setPFP] = useState(false);

  const bigDevice = useMediaQuery('(min-width:600px)');

  // const parallax = useRef<IParallax>(null);
  return (
      <Parallax  pages={16} >
      <ParallaxLayer
          offset={0}
          speed={0}
          factor={7}
          style={{
            backgroundImage: url('stars', true),
            backgroundSize: 'cover',
          }}
        />
      <ParallaxLayer
          offset={1}
          speed={0}
          factor={7}
        >

        </ParallaxLayer>

             <ParallaxLayer
          offset={0}
          speed={0}
          factor={1}
          style={{
            backgroundImage: url('stars', true),
            backgroundSize: 'cover',
          }}
        />
      <ParallaxLayer
          offset={7}
          speed={0}
          factor={7}
          style={{
            backgroundImage: url('stars', true),
            backgroundSize: 'cover',
          }}
        />
      <ParallaxLayer offset={0}  factor={1} speed={0.3} 
           style={{
            backgroundImage: 'url(/images/bg1.png)' ,
            backgroundSize: 'cover',
            zIndex:-1
          }}
      />
       <ParallaxLayer offset={0}  factor={1} speed={0.3} >
           <Hero/>
       </ParallaxLayer>
       <ParallaxLayer offset={1}  factor={1} speed={0.1} style={{display:'flex'}}>
          <Waypoint  topOffset="-400px"    onEnter={() => { setVision(true); }} />
           <Vision transition={showVision}/>
       </ParallaxLayer>

          <ParallaxLayer
        sticky={{ start: bigDevice? 2.1 : 2.03, end: 5}}
        style={{height: bigDevice ?  190 : 140,display:'flex',justifyContent:'center',alignItems:"flex-end"}}
        speed={-0.2}>
          <FictionTitle/>
      </ParallaxLayer>

       <ParallaxLayer offset={2}  factor={1} speed={0.1}  id='#fiction'
       >
               <Waypoint  topOffset="-150px"   onEnter={() => { setFictionA(true); }}  onLeave={() => { setFictionA(false); }} />
         <FictionA transition={showFictionA}/>
       </ParallaxLayer>
       <ParallaxLayer offset={2}  factor={1} speed={0.2}   style={{
          zIndex:-1,
            backgroundImage: 'url(/images/bg1.png)' ,
            backgroundSize: 'cover',
          }}>
       </ParallaxLayer>

       <ParallaxLayer offset={3}  factor={1} speed={0.1} >
        <Waypoint   topOffset="-150px"  onEnter={() => { setFictionB(true); }}  onLeave={() => { setFictionB(false);}}  />
        <FictionB transition={showFictionB}/>
       </ParallaxLayer>
       <ParallaxLayer offset={3}  factor={1} speed={0.2}   style={{
        zIndex:-1,
            backgroundImage: 'url(/images/bg1.png)' ,
            backgroundSize: 'cover',
          }}>
       </ParallaxLayer>
       <ParallaxLayer offset={4}  factor={1} speed={0.1}  >
        <Waypoint   topOffset="-150px"  onEnter={() => { setFictionC(true); }} onLeave={() => { setFictionC(false);}}  />
        <FictionC transition={showFictionC}/>
       </ParallaxLayer>
       <ParallaxLayer offset={4}  factor={1} speed={0.2}   style={{
        zIndex:-1,
            backgroundImage: 'url(/images/bg1.png)' ,
            backgroundSize: 'cover',
          }}>
       </ParallaxLayer>
       <ParallaxLayer offset={5}  factor={1} speed={0.1} >
       <Waypoint  topOffset="-150px"   onEnter={() => { setFictionD(true); }}  />
         <FictionD transition={showFictionD}/>
       </ParallaxLayer>

       <ParallaxLayer
        sticky={{ start: 6, end: 8.9}}
        offset={0.2}
        style={{height: bigDevice ?  190 : 140,zIndex:30,display:'flex',justifyContent:'center',alignItems:"flex-end"}}
        speed={0.2}>
          <CollectiblesTitle/>
      </ParallaxLayer>
      <LogoFormationNFTlayer />
      <ParallaxLayer offset={6.2}  factor={1} speed={0.2}  style={{zIndex:30}}>
           <CollectiblesA/>
       </ParallaxLayer>

       <ParallaxLayer offset={7.4}  factor={1} speed={0.2} sticky={{ start: 7, end: 9}} >
           <CollectiblesB transition={showPFP} />
       </ParallaxLayer>
       <ParallaxLayer offset={7.4}  factor={1} speed={0.2} sticky={{ start: 7, end: 8}} >
       <Waypoint  
           onEnter={({ previousPosition, currentPosition }) => { 
            if(previousPosition==='above' && currentPosition==="inside"){
              setPFP(false);
            }}}
               />
       </ParallaxLayer>
       <ParallaxLayer offset={8.4}  factor={1} speed={0.2} sticky={{ start: 8, end: 9}}>
       <Waypoint  
          onLeave={({ previousPosition, currentPosition }) => { 
            if(previousPosition==='inside' && currentPosition==="above"){
              setPFP(true);
            }}}
               />
       </ParallaxLayer>

      <ParallaxLayer offset={10}  factor={1} speed={0.2} style={{ zIndex:30}}>
         <NftStatistics/>
       </ParallaxLayer>

 

       <ParallaxLayer offset={11}  factor={1} speed={0.2} style={{ zIndex:30}}>
       <DNA/>
       </ParallaxLayer>

       <ParallaxLayer offset={12}  factor={1} speed={0.2} style={{ zIndex:30}}>
       <MindMap/>
       </ParallaxLayer>

       <ParallaxLayer offset={13}  factor={1} speed={0.2} style={{ zIndex:30}}>
           <Team/>
       </ParallaxLayer>

       <ParallaxLayer offset={14}  factor={1} speed={0.2} style={{ zIndex:30}}>
           <FAQ/>
       </ParallaxLayer>

          <ParallaxLayer offset={15}  factor={1} speed={0.2}>
          <Footer/>
       </ParallaxLayer>

        </Parallax>
  )
}

export default ParallaxPage;


export function LogoFormationNFTlayer() {
  const bigDevice = useMediaQuery('(min-width:600px)');
  return (
  <>  
                 <ParallaxLayer  offset={bigDevice ? 6 : 6} speed={0.2}        style={{height:100}}>
          <div   style={{ position: 'absolute', width: '6%', left: bigDevice ? '80%' : '83%'}} >
             <NftCard dense imagen={'/images/pussymonkey1.png'}/> </div>
          <div   style={{ position: 'absolute', width: '6%', left: bigDevice ? '17%' : '4%'}} >
          <NftCard dense imagen={'/images/pussymonkey1.png'}/> </div>
        </ParallaxLayer>

        <ParallaxLayer offset={bigDevice ? 6 : 6} speed={0.2}   style={{height:100}}>
              <div  style={{position: 'absolute',  width: '6%', left:  bigDevice ? '32%' : '25%' }} >
              <NftCard dense imagen={'/images/pussymonkey1.png'}/></div>
          <div  style={{position: 'absolute',  width: '6%', left: bigDevice ? '65%' : '60%'  }} >
          <NftCard dense imagen={'/images/pussymonkey1.png'}/> </div>
        </ParallaxLayer>

        <ParallaxLayer offset={bigDevice ? 6.3 : 6.3} speed={0.2}   style={{height:100}}>
              <div  style={{position: 'absolute',  width: '6%', left:  bigDevice ? '80%' : '4%' }} >
              <NftCard dense imagen={'/images/pussymonkey1.png'}/></div>
          <div  style={{position: 'absolute',  width: '6%', left: bigDevice ? '17%' : '83%'  }} >
          <NftCard dense imagen={'/images/pussymonkey1.png'}/> </div>
        </ParallaxLayer>

        {/* <ParallaxLayer offset={bigDevice ? 6.5 : 6.42} speed={0.2}   style={{height:100}}>
              <div  style={{position: 'absolute',  width: '6%', left:  bigDevice ? '80%' : '83%'}} >5
              <NftCard dense imagen={collectible2}/> </div>
          <div  style={{position: 'absolute',  width: '6%', left: bigDevice ? '17%' : '4%'  }} >6
          <NftCard dense imagen={collectible2}/> </div>
        </ParallaxLayer> */}

        
        <ParallaxLayer offset={bigDevice ? 5.98 : 6.55} speed={0.2}   style={{height:100}}>
              <div   style={{ position: 'absolute', width: '6%', left: bigDevice ? '80%' : '83%' }} >
              <NftCard dense imagen={'/images/pussymonkey1.png'}/> </div>
          <div   style={{ position: 'absolute',width: '6%', left: bigDevice ? '17%' : '4%' }} >
          <NftCard dense imagen={'/images/pussymonkey1.png'}/> </div>
        </ParallaxLayer>

        <ParallaxLayer offset={bigDevice ? 6.3 : 6.55} speed={0.2}   style={{height:100}}>
              <div  style={{position: 'absolute',  width: '6%', left:  bigDevice ? '32%' : '25%'}} >
              <NftCard dense imagen={'/images/pussymonkey1.png'}/></div>
          <div  style={{position: 'absolute',  width: '6%',left: bigDevice ? '65%' : '60%'  }} >
          <NftCard dense imagen={'/images/pussymonkey1.png'}/> </div>
        </ParallaxLayer>
{/* 

        <ParallaxLayer offset={bigDevice ? 6: 6.71} speed={0.2}    style={{height:100}}>
              <div   style={{ position: 'absolute', width: '6%', left: '65%'  }} >9
              <NftCard dense imagen={collectible2}/> </div>
          <div  style={{ position: 'absolute',width: '6%', left: bigDevice ? '32%' : '35%' }} >11
          <NftCard dense imagen={collectible2}/></div>
        </ParallaxLayer> */}
  </>
  );
}