import React, { useContext } from 'react';
import Paper from '@mui/material//Paper';
import Typography from '@mui/material//Typography';
import { LanguageContext } from "../../contexts/languageContext";

const CollectiblesTitle = () => {
  const { t } = useContext(LanguageContext);
  return (
    <Paper variant='outlined' sx={{px:3,py:2}}>
      <Typography variant='h4' align="center" >
      {t("collectibles")}
      </Typography>
    </Paper>
  );
}

export default CollectiblesTitle;