import React from 'react';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

export default function CircleItemSmall(props) {
  return (
       <Tooltip title={props.title} placement="top-start">
        <Grid item container style={{borderRadius:5}} justifyContent="center" alignItems="center" p={2}>
        <Grid item xs={3} container alignItems="center" justifyContent="center">{props.icon}</Grid>
      </Grid>
</Tooltip>
  )
}