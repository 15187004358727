import React from "react";
import { Grid, Typography, Button, Container } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { useGlitch } from "react-powerglitch";
import { styled } from "@mui/material/styles";
import { LanguageContext } from "../contexts/languageContext";

const Hero = () => {
  const { t } = React.useContext(LanguageContext);
  const matches = useMediaQuery("(min-width:600px)");
  // const glitch1 = useGlitch({
  //   "playMode": "always",
  //   "createContainers": true,
  //   "hideOverflow": false,
  //   "timing": {
  //     "duration": 1500,
  //     "easing": "ease-in"
  //   },
  //   "glitchTimeSpan": {
  //     "start": 0.5,
  //     "end": 0.7
  //   },
  //   "shake": {
  //     "velocity": 15,
  //     "amplitudeX": 0.2,
  //     "amplitudeY": 0.2
  //   },
  //   "slice": {
  //     "count": 6,
  //     "velocity": 15,
  //     "minHeight": 0.02,
  //     "maxHeight": 0.10,
  //     "hueRotate": true
  //   },
  //   "pulse": false
  // });

  // const glitch2 = useGlitch({
  //   "playMode": "always",
  //   "createContainers": true,
  //   "hideOverflow": false,
  //   "timing": {
  //     "duration": 1500,
  //     "easing": "ease-in"
  //   },
  //   "glitchTimeSpan": {
  //     "start": 0.7,
  //     "end": 0.9
  //   },
  //   "shake": {
  //     "velocity": 15,
  //     "amplitudeX": 0.2,
  //     "amplitudeY": 0.2
  //   },
  //   "slice": {
  //     "count": 6,
  //     "velocity": 15,
  //     "minHeight": 0.02,
  //     "maxHeight": 0.10,
  //     "hueRotate": true
  //   },
  //   "pulse": false
  // });

  const HeroBox = styled(Container)(({ theme }) => ({
    zIndex: 30,
    width: "100%",
    display: "flex",
    minHeight: "600px",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "20px",
    },
  }));

  const GridContainer = styled(Grid)(({ theme }) => ({
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(2px)",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      padding: "5vh 20px 20px 20px",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "90%",
      padding: "120px 50px 50px 50px",
    },
    borderRadius: 15,
    margin: "auto",
  }));

  const Title = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      paddingBottom: 0,
    },
    [theme.breakpoints.up("md")]: {},
  }));

  const Subtitle = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      paddingBottom: "10px",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "30px",
      fontSize: "1.7rem",
    },
  }));

  return (
    <HeroBox maxWidth={"xl"}>
      <GridContainer container>
        <Grid
          item
          xs={12}
          md={6}
          px={matches ? 5 : 0}
          container
          direction="column"
          spacing={3}
          pt={matches ? 5 : 10}
          position="relative" /* sx={{backgroundImage:`url(${circ})`,backgroundSize:'contain',backgroundRepeat:'no-repeat'}} */
        >
          <Grid item></Grid>
          <Grid item>
            <div>
              <Title variant="h1" fontWeight={300} color="secondary.light">
                {t("de")}{" "}
                <Typography
                  // ref={glitch1.ref}
                  component="span"
                  variant="h1"
                  fontWeight={300}
                  color="primary.light"
                >
                  {t("broke")}{" "}
                </Typography>
                <Typography
                  //  ref={glitch2.ref}
                  component="span"
                  variant="h1"
                  fontWeight={300}
                  color="text.primary"
                >
                  {" "}
                  {t("free")}{" "}
                </Typography>
              </Title>
            </div>
          </Grid>

          <Grid item>
            <Subtitle variant="h5" color="text.primary">
              {t("heroSubtitle")}
            </Subtitle>
          </Grid>

          <Grid
            item
            container
            /*sx={{backgroundColor:'rgba(0,0,0,0.7)'}} */ alignItems="center"
          >
            <Button
              variant="contained"
              color="secondary"
              size="large"
              sx={{ color: "#ffff", fontWeight: "bold", px: 5, py: 2 }}
            >
              {t("getWhitelisted")}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} pt={matches ? 0 : 2}></Grid>
        <Grid
          item
          xs={12}
          align="center"
          sx={{ width: "100%" }}
          pt={{ xs: 13, md: 10 }}
        >
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            sx={{ display: matches ? "flex" : "flex" }}
          >
            {t("scroll")}{" "}
            <div className="scrolldown">
              <div className="chevrons">
                <div className="chevrondown"></div>
                <div className="chevrondown"></div>
              </div>
            </div>{" "}
            {t("down")}
          </Grid>
        </Grid>
      </GridContainer>
    </HeroBox>
  );
};

export default Hero;
