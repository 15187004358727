import * as React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import { LanguageContext } from "../../contexts/languageContext";

export default function MindMapLayer() {
  const { t } = React.useContext(LanguageContext);
  const HeroBox = styled(Container)(({ theme }) => ({
    zIndex: 30,
    width: "100%",
    display: "flex",
    minHeight: "600px",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "20px",
    },
  }));

  const GridStatsContainer = styled(Grid)(({ theme }) => ({
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(2px)",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "90%",
      padding: 50,
    },
    borderRadius: 15,
    margin: "auto",
  }));

  return (
    <HeroBox maxWidth={"xl"}>
      <GridStatsContainer
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Grid container alignItems="center" justifyContent="center">
          <Paper variant="outlined" sx={{ px: 3, py: 2, mb: 5 }}>
            {" "}
            <Typography id="fiction" textAlign="center" variant="h4">
            {t("mindmapTitle")}
            </Typography>
          </Paper>{" "}
        </Grid>

        <Grid container justifyContent="center" item md={10}>
          <img src="/images/mindmap.png" alt="mindmap" />
        </Grid>
      </GridStatsContainer>
    </HeroBox>
  );
}
